import { Box, InputLabel, Slider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSession } from 'next-auth/react';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BotContentTextField } from '@/components/custom-form-text-field';
import { NodeOutputDetail } from '@/components/node-output-detail';
import { NodeFormProps } from '@/domain/node-form-props';
import { useSaveBotVersionEnabled } from '@/utils/use-save-bot-version-enabled';

export const RetryOptionsBlock: FC<NodeFormProps> = ({ control }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { data: session } = useSession();
    const isBotEditor = !!session?.user?.roles?.isBotEditor;
    const saveEnabled = useSaveBotVersionEnabled();

    return (
        <Box
            sx={{
                border: `solid 3px ${theme.palette.secondary.veryLight}`,
                borderRadius: '10px',
                p: 2,
                marginBottom: '1rem',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <Typography variant='cardTitle'>{t('retry')}</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    mb: '10px',
                }}
            >
                <Box sx={{ width: '50%' }}>
                    <InputLabel
                        className='input-label-card'
                        sx={{ marginBottom: '10px' }}
                    >
                        {t('number_retries')}:
                    </InputLabel>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '50px',
                        }}
                    >
                        <Controller
                            name='retries'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Slider
                                    {...field}
                                    min={0}
                                    max={5}
                                    valueLabelDisplay='auto'
                                    disabled={!saveEnabled}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Box sx={{ width: '50%' }}>
                    <InputLabel
                        className='input-label-card'
                        sx={{ marginBottom: '10px' }}
                    >
                        {t('try_again_prompt')}
                    </InputLabel>
                    <Controller
                        name='try_again_prompt'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <BotContentTextField
                                {...field}
                                fieldError={error}
                                fullWidth
                                autoResize
                                disabled={!saveEnabled}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Box>
                <InputLabel
                    className='input-label-card'
                    sx={{ marginBottom: '10px' }}
                >
                    {t('max_retries_reached_prompt')}
                </InputLabel>
                <Controller
                    name='max_retries_reached_prompt'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <BotContentTextField
                            {...field}
                            fieldError={error}
                            fullWidth
                            disabled={!isBotEditor}
                            autoResize
                        />
                    )}
                />
            </Box>
            <Box sx={{ backgroundColor: 'secondary.bgPinkDark' }}>
                <Typography
                    variant='h4'
                    marginBottom={'20px'}
                    marginTop={'25px'}
                >
                    {t('output_title')}
                </Typography>
                <div className='input-label-card'>
                    <NodeOutputDetail
                        text={t('numeric_rating_fallback')}
                        dotColor={'red'}
                    />
                    <NodeOutputDetail
                        text={t('numeric_rating_regular')}
                        dotColor={'black'}
                    />
                </div>
            </Box>
        </Box>
    );
};
