import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface NodeOutputsDetailsProps {
    children: ReactNode;
}

export function NodeOutputsDetails({ children }: NodeOutputsDetailsProps) {
    const { t } = useTranslation();

    return (
        <Box sx={{ padding: 2, backgroundColor: 'secondary.bgPinkDark' }}>
            <Typography variant='h3' gutterBottom marginTop={'10px'}>
                {t('output_title')}
            </Typography>

            <Box display='flex' flexWrap='nowrap' flexDirection='column'>
                {children}
            </Box>
        </Box>
    );
}
