import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { shallow } from 'zustand/shallow';

import { LanguageSection } from './language-section';
import { ProfileSection } from './profile-section';

import { DrawerButton } from '@/components/button/icon-button';
import TelmaGuideRedirect from '@/layout/main-layout/header/telma-guide/telma-guide-redirect';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export const Header: FC = () => {
    const theme: any = useTheme();

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <DrawerButton />
            </Box>
            <Box sx={{ flexGrow: 1 }} />

            {/* right side of the Header */}
            <TelmaGuideRedirect />
            <LanguageSection isNarrowBar={false} />
            <ProfileSection isNarrowBar={false} />
        </>
    );
};
