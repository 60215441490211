import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box, useTheme } from '@mui/system';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

export default function TelmaGuideRedirect() {
    const { t } = useTranslation();

    return (
        <>
            <Box>
                <Link href='/telma-guide' passHref>
                    <Tooltip title={t('go_docs')}>
                        <IconButton
                            aria-label='documentation'
                            sx={{
                                color: 'white',
                                transition: 'transform 0.2s, color 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.2)',
                                    boxShadow:
                                        '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            <HelpOutlineIcon
                                sx={{
                                    color: '#dd8a87',
                                    '&:hover': {
                                        color: 'white',
                                    },
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Link>
            </Box>
        </>
    );
}
