export const isInRange = (
    breakpoint: number,
    min: number | undefined,
    max: number | undefined,
): boolean | void => {
    if (min !== undefined && max !== undefined) {
        if (min < max) {
            return breakpoint >= min && breakpoint <= max;
        } else {
            return breakpoint <= min && breakpoint >= max;
        }
    }
};

export function calculateIntervals(
    minV: number,
    maxV: number,
    partitionPoints: number[],
) {
    if (isNaN(minV) || isNaN(maxV)) return [];
    const intervalPartitionPointsInclMinAndMax = [
        minV - 1,
        ...partitionPoints,
        maxV,
    ];

    const intervals = [];
    for (let i = 0; i < intervalPartitionPointsInclMinAndMax.length - 1; i++) {
        const start = intervalPartitionPointsInclMinAndMax[i] + 1;
        const end = intervalPartitionPointsInclMinAndMax[i + 1];
        intervals.push({ start, end });
    }
    return intervals;
}
